#form__lable,
.form__lable {
  width: calc(33% - 10px);
}
#hero__slider__foreground,
.hero__slider__foreground {
  right: 5vw;
}
#hero__slider__text,
.hero__slider__text {
  left: 5vw;
}
#hero__slider__subText,
.hero__slider__subText {
  left: 7.5vw;
}
